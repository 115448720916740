/**** 서브페이지 타이틀 영역 ****/
.sub_title_banner{
    position: relative;
    display: flex;
    flex-direction:column;
    justify-content: center;
    height: 200px;
    background: url(../img/bg.jpg) center center no-repeat;
    background-size: cover;
}
.sub_title_banner::before{
    width:100%;
    height: 100%;
    position: absolute;
    background: rgba(0,0,0,0.3);
    content: '';
}
.sub_title_banner .tit{
    position: relative;
    text-align: center;
    color: #fff;
    font-weight:400;
    font-size: 2.571em;
    z-index: 1;
}
.relation-img{
    width : 100%;
    height : 50px;
    margin-bottom: 30px;
}
.business_table{
    width : 100%;
    border : 1px solid #9A9A9A;
}
.business_table th, .business_table td{
    border : 1px solid #9A9A9A;
}
.business_table2 td{
    height : 30px;
    padding : 10px;
}
.business_table2{
    width : 100%;
    border : 1px solid #9A9A9A;
    font-size: 13px;
}
.business_table2 th, .business_table2 td{
    border : 1px solid #9A9A9A;

}
@media screen and (max-width:767px){
    .sub_title_banner{height: 130px;}
}
/**** 리본바 영역 영역 ****/
.sub_reborn{
    border-bottom: 1px solid #efefef;
}
    .sub_reborn ul{
        display: flex;
        flex-wrap: nowrap;
        max-width: 1280px;
        margin:0 auto;
    }
        .sub_reborn ul li{
            line-height: 50px;
        }
            .sub_reborn ul li a{
                display: block;
                padding:0 25px 0 20px;
                background: url(../img/reborn_arrow.png) right center no-repeat;
            }
            .sub_reborn ul li:first-of-type a{
                padding:0 20px;
                color: #fff;
                background: #1e90ff;
            }
            .sub_reborn ul li:last-of-type a{background: none;}
@media screen and (max-width:767px){
    .sub_reborn ul li{line-height: 40px;}
    .sub_reborn ul li a{padding:0 15px 0 10px;}
    .sub_reborn ul li:first-of-type a{padding:0 15px;}
}
.sub_conbox{
    max-width: 1280px;
    margin:0 auto;
    padding:40px 0;
    box-sizing: border-box;
}
.sub_conbox img{
    max-width: 100%;
}
@media screen and (min-width:1280px){
    .sub_conbox{
        min-height: calc(100vh - 459px);
    }
}
@media screen and (max-width:1280px) and (min-width:768px){
    .sub_conbox{
        min-height: calc(100vh - 443px);
    }
}
@media screen and (max-width:767px){
    .sub_conbox{
        min-height: calc(100vh - 326px);
    }
}
    /*** 인사말 ***/
    .greeting{
        word-break: keep-all;
        margin-bottom: 30px;
    }
        .greeting h2{
            max-width:900px;
            margin:40px auto;
            text-align: center;
            font-size: 2.143em;
            word-break: keep-all;
        }
        .greeting .subtext{
            max-width:900px;
            margin:0 auto;
            text-align: center;
            font-size: 1.143em;
        }
        .greeting .detail{
            display: flex;
            margin-top:90px;
            margin-bottom: 70px;
        }
            .greeting .detail .imgbox{
                width:35%;
            }
            .greeting .detail .imgbox img{
                width:100%; height: 100%;
                object-fit: cover;
            }
            .greeting .detail .textbox{
                width:65%;
                padding:10px 40px 30px;
                box-sizing: border-box;
            }
                .greeting .textbox h4{
                    font-size: 1.357em;
                    margin:20px 0 10px;
                }
                .greeting .textbox p{
                    margin-bottom: 50px;
                    font-size: 1.143em;
                }
                .greeting .textbox p:last-of-type{margin-bottom: 0;}
@media screen and (max-width:1279px){
    .greeting{
        padding:0 10px;
        box-sizing: border-box;
    }
    .greeting h2{margin:40px auto 20px;}
    .greeting .subtext{max-width: 80%;}
    .greeting .detail{margin-top: 50px;}
    .greeting .detail .textbox{padding-right: 0;}
    .greeting .textbox p{margin-bottom: 30px;}
}
@media screen and (max-width:767px){
    .greeting h2{width:100%; font-size: 1.8em;}
    .greeting .subtext{max-width: 100%;}
    .greeting .detail{
        flex-wrap: wrap;
        margin-top:30px;
        margin-bottom: 50px;
    }
    .greeting .detail .imgbox{
        width:100%; height: 200px;
    }
    .greeting .detail .textbox{
        width:100%;
        padding:0;
        text-align: center;
        margin-top:20px;
    }
    .greeting .textbox p{margin-bottom: 20px;}
}
    /*** 자료실 ***/
    .reperence{}
        .tablebox{}
            .h_table{
                width:100%;
                table-layout: fixed;
            }
                .h_table th{
                    height: 50px;
                    background: #efefef;
                }
                .h_table th.min{
                    width:150px;
                }
                .h_table tr:hover{
                    background : #e7e6e6;
                }
                .h_table th:first-of-type{border-radius:5px 0 0 5px;}
                .h_table th:last-of-type{border-radius:0 5px 5px 0;}
                .h_table td{
                    height: 45px;
                    padding:0 7px;
                    border-bottom: 1px solid #efefef;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                .h_table td.notice{
                    font-weight: 500;
                    color: #1e90ff;   
                }
        .h_pager{}
            .h_pager ul{
                display: flex;
                flex-wrap: nowrap;
                justify-content: center;
                margin:40px 0;
            }
                .h_pager ul li{
                    width:35px; height: 38px;
                    margin-right: 3px;
                    line-height: 38px;
                    text-align: center;
                    font-size: 0.929em;
                    color: #666;
                    border:1px solid #efefef;
                }
                .h_pager ul li.on{
                    color: #fff;
                    background: #1e90ff;
                }
                .h_pager ul li:last-of-type{margin-right: 0;}
                .h_pager ul li.first{
                    text-indent: -6000px;
                    background: url(../img/first.png) center center no-repeat;
                }
                .h_pager ul li.prev{
                    text-indent: -6000px;
                    background: url(../img/prev.png) center center no-repeat;
                }
                .h_pager ul li.next{
                    text-indent: -6000px;
                    background: url(../img/next.png) center center no-repeat;
                }
                .h_pager ul li.last{
                    text-indent: -6000px;
                    background: url(../img/last.png) center center no-repeat;
                }
        @media screen and (max-width:1279px){
            .tablebox{padding:0 10px;}
        }
        @media screen and (max-width:767px){
            .tablebox{padding:0 10px;}
            .h_table th{height: 40px;}
                .h_table th.min{width:60px;}
                .h_table td{
                    height: 35px;
                    padding:0 3px;
                    border-bottom: 1px solid #efefef;
                }
            .h_pager{padding:0 10px;}
            .h_pager ul{margin:20px 0;}
            .h_pager ul li{
                width:28px;
                height: 28px;
                line-height: 28px;
            }
        }

    /*** 문의하기 ***/
    .sub_conbox.ask{
        display: flex;
        width:100%;
    }
        .ask .infobox{
            width:300px;
            height: 692px;
            padding:40px;
            box-sizing: border-box;
            background: url(../img/bg_ask.jpg) right top no-repeat;
            background-size: 1200px;
        }
            .ask .infobox li{
                color: #ccc;
                margin-bottom: 20px;
                text-align: right;
            }
                .ask .infobox li span{
                    display: block;
                }
                .ask .infobox li span.tit{
                    font-weight: 500;
                    font-size: 2em;
                }
        .ask .askbox{
            width:60%;
            margin:0 auto;
            padding:20px 40px;
            text-align: left;
        }
            .ask .askbox h2{margin-bottom: 20px; font-size: 2em;}
            .ask .askbox p{margin-bottom: 30px;}
            .askbox .form{
                 box-sizing: border-box;
            }
                .askbox .form ul{}
                .askbox .form ul li{
                    display: flex;
                    flex-wrap: nowrap;
                    white-space: nowrap;
                }
                .askbox .form label{
                    display: block;
                    width:80px;
                    line-height: 50px;
                    padding-left: 10px;
                    text-align: left;
                    font-weight: 400;
                }
                .askbox .form .ask_input{
                    width:100%; height: 50px;
                    margin-bottom: 20px;
                    padding:0 10px;
                    box-sizing: border-box;
                    font-weight: 300;
                    font-size: 1em;
                    border:none;
                    border-bottom: 1px solid #333;
                }
                .askbox .form textarea{
                    width:100%;
                    margin-bottom: 20px;
                    padding:10px;
                    line-height: 1.6;
                    letter-spacing: -0.7px;
                    font-size: 1em;
                    border:1px solid #fff;
                    border-bottom-color: #333;
                    box-sizing: border-box;
                    resize: none;
                }
                .askbox .form textarea:focus{
                    border: 1px solid #333; outline: none;
                }
                .askbox .ask_btn{
                    width:100%; height: 50px;
                    font-size: 1.143em;
                    color: #fff;
                    border:none;
                    background: #333;
                    cursor: pointer;
                    letter-spacing: -0.7px;
                }
@media screen and (max-width:1279px){
    .sub_conbox.ask{padding:40px 10px;}
}
@media screen and (max-width:767px){
    .sub_conbox.ask{
        flex-direction: column;
        padding:40px 10px 20px;
    }
    .ask .infobox{
        position: relative;
        width:100%; height: 100px;
        padding:20px;
        background-position: center center;
        background-size: cover;
    }
        .ask .infobox::after{
            content: '';
            left:0; top:0;
            width:100%; height: 100%;
            position: absolute;
            background: rgba(0,0,0,0.5);
        }
            .ask .infobox ul{
                display: flex;
                justify-content: space-between;
            }
                .ask .infobox ul li{
                    position: relative;
                    text-align: center;
                    color:#fff;
                    z-index: 1;
                 }
    .ask .askbox{
        width:100%;
        padding:20px 0;
        box-sizing: border-box;
        text-align: center;
    }
    .ask .askbox h2{margin:20px 0 10px;}
    .askbox .form label{width:60px; line-height: 35px;}
    .askbox .form .ask_input{
        height: 35px;
        margin-bottom: 10px;
    }
}

.sub_list {
    width : 25%;
    padding : 25px 25px 0px 25px;
    margin : 0px;
}
.sub_list > div > ul:hover{
    background-color: #1e90ff;
    color : white;
}

.sub_list > div > ul {
    margin : 0px;
    padding : 14px 16px;
    font-size: 18px;
    border-top: 1px solid #ccc;
}
.sub_list > div > div {
    background-color: #f7f7f7;
    font-size: 14px;
    padding : 8px 35px 8px 16px;
}
.sub_list > div > div > li{
    margin : 4px 0px;
}
.sub_list > div > div > li > a:hover {
    color : #00bbff;
}
.sub_contents {
    width : 65%;
}
@media screen and (max-width : 860px){
    .sub_list {
        display: none;
    }
    .sub_contents{
        width : 100%
    }
}
@media screen and (max-width : 767px) {
    .one_tpc {
        font-size: 16px;
    }
}