/**** Font ****/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700&display=swap');

.font-eng {font-family: 'Playfair Display', serif;}
.font-sans {font-family: 'Montserrat', sans-serif;}

/**** font-weight ****/
.fw-300{font-weight: 300;}
.fw-400{font-weight: 400;}
.fw-500{font-weight: 500;}
.fw-600{font-weight: 600;}
.fw-700{font-weight: 700;}
.fw-800{font-weight: 800;}

/**** color ****/
h1, h2, h3, h4, h5, h6{color: #3f4448;}

/**** RESET ****/
*{margin:0; padding:0;}
body, textarea{font-family: 'Noto Sans KR', sans-serif; line-height: 1.682; font-size: 14px; letter-spacing: -0.7px; font-weight: 300; color: #000;}
input[type=text], input[type=password], input[type=number]{font-family: 'Noto Sans KR', sans-serif; line-height: 1.682; font-size: 14px;}
em{font-style: normal;}
a{text-decoration: none; color: #000; font-weight: 300;}
a:hover{color: #333;}
p{font-weight: 300;}
ul, li{list-style: none;}
button{letter-spacing: -0.7px;}
button:focus{outline: none;}
.skip, .blind{display: none;}


.research-content {
    border: 1px solid gray;
    padding: 10px;
    margin-bottom : 20px;
}
.research-table label {
    margin-right:  10px;
}
.research-content input {}
