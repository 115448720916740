.h_footer{
    width:100%;
    padding:40px 10px;
    background: #333;
    text-align: center;
    box-sizing: border-box;
    font-size: 0.929em;
}
.h_footer ul{
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-bottom: 7px;
}
    .h_footer ul li{
        position: relative;
        color: #777; margin-right: 20px;
    }
    .h_footer ul li:last-of-type{margin-right: 0;}
    .h_footer ul li::after{
        position: absolute;
        display: block;
        right:-10px;
        top:12px;
        width:1px; height: 1px;
        background: #777;
        content: '';
    }
    .h_footer ul li:last-of-type::after{
        display: none;
    }
.h_footer address{font-style: normal; font-size: 0.929em; color: #777; word-break: keep-all;}
@media screen and (max-width:767px){
    .h_footer{padding:25px 10px;}
    .h_footer ul{
        flex-wrap: wrap;
        word-break: keep-all;
    }
    .h_footer ul li{
        display: inline-block;
        margin:0 5px;
    }
    .h_footer ul li:first-of-type{width:100%;}
    .h_footer ul li::after{display: none;}
}
