.h_header_wrap{width:100%;}
.h_header_wrap .h_header{
    display: flex;
    flex-wrap: nowrap;
    justify-content:space-between;
    height: 80px;
    white-space: nowrap;
    margin:0 auto;
    max-width:1280px;
}
/* 로고 */
.h_header_wrap .h_header .h_logo{
    padding-left: 45px;
    font-size: 16px;
    font-weight: 400;
    line-height: 80px;
    background: url(../img/logo.jpg) left center no-repeat;
    background-size: 40px;
    margin: 0px;
}
.h_header_wrap .h_header .h_logo a{display: block;}
@media screen and (max-width:1279px) and (min-width:768px){
    .h_header_wrap .h_header{height: 70px;}
    .h_header_wrap .h_header .h_logo{
        width:108px;
        margin-left: 20px;
        padding:0;
        line-height: 70px;
        text-indent: -6000px;
    }
}
/* 내비게이션 */
.h_header_wrap .h_header .h_nav{width:100%;}
    .h_header_wrap .h_header .h_nav ul.h_level1{
        display: flex;
        flex-wrap: nowrap;
        justify-content:center;
    }
        .h_header_wrap .h_header .h_nav li.h_level1{
            position: relative;
            margin-left: 50px;
            font-size: 1.071em; /* 15px */
            box-sizing: border-box;
        }
        .h_header_wrap .h_header .h_nav li.h_level1:first-of-type{margin-left: 0;}
            .h_header_wrap .h_header .h_nav li.h_level1 > p,
            .h_header_wrap .h_header .h_nav li.h_level1 > a{
                display: block; height: 80px;
                font-weight: 400;
                line-height: 80px;
                padding:0 20px;
                box-sizing: border-box;
                border-bottom: 2px solid #fff;
            }
        @media screen and (min-width:768px){
            .h_header_wrap .h_header .h_nav li.h_level1:hover > p,
            .h_header_wrap .h_header .h_nav li.h_level1:hover > a{border-bottom: 2px solid #1e90ff;}
            .h_header_wrap .h_header .h_nav li.h_level1 > .tpc_skip,
            .h_header_wrap .h_header .h_nav li.h_level1 > .pc_skip{display: none;}
        }
        @media screen and (max-width:1279px) and (min-width:768px){
            .h_header_wrap .h_header .h_nav li.h_level1{margin-left: 20px;}
            .h_header_wrap .h_header .h_nav li.h_level1 > p,
            .h_header_wrap .h_header .h_nav li.h_level1 > a{line-height: 70px; height: 70px;}
        }
            .h_header_wrap .h_header .h_nav ul.h_level2{
                display: none;
                position: absolute;
                padding:10px 0;
                line-height: 40px;
                border:1px solid #efefef;
                box-sizing: border-box;
                min-width:200px;
                background: #fff;
                z-index: 5;
            }       
        @media screen and (max-width:1279px) and (min-width:768px){
            .h_header_wrap .h_header .h_nav ul.h_level2{
                min-width: 180px;
                line-height: 35px;
            }
        }
        @media screen and (min-width:768px){
            .h_header_wrap .h_header .h_nav li.h_level1:hover ul.h_level2{display: block;}
                .h_header_wrap .h_header .h_nav li.h_level2{
                    position: relative;
                    z-index: 5;
                }
                    .h_header_wrap .h_header .h_nav li.h_level2 > a{
                        display: block;
                        padding:0 20px;
                        box-sizing: border-box;
                    }
                    .h_header_wrap .h_header .h_nav li.h_level2 > .tpc_skip,
                    .h_header_wrap .h_header .h_nav li.h_level2 > .pc_skip{display: none;}
                    .h_header_wrap .h_header .h_nav li.h_level2:hover > a{
                        color: #fff;
                        background: #1e90ff;
                    }
                    .h_header_wrap .h_header .h_nav ul.h_level3{
                        display: none;
                        position: absolute;
                        right:-200px; top:0;
                        min-width:200px;
                        padding:10px 0;
                        border:1px solid #efefef;
                        box-sizing: border-box;
                        background: #fff;
                        z-index: 5;
                    }
        }
                @media screen and (max-width:1279px) and (min-width:768px){
                    .h_header_wrap .h_header .h_nav ul.h_level3{
                        right:-180px;
                        min-width: 180px;
                    }
                }
                @media screen and (min-width:768px){
                    .h_header_wrap .h_header .h_nav li.h_level2:hover ul.h_level3{
                        display: block;
                    }
                        .h_header_wrap .h_header .h_nav li.h_level3{}
                            .h_header_wrap .h_header .h_nav li.h_level3 > a{
                                display: block;
                                padding:0 20px;
                                box-sizing: border-box;
                            }
                            .h_header_wrap .h_header .h_nav li.h_level3:hover > a{
                                color: #fff;
                                background: #1e90ff;
                            }
                }

@media screen and (max-width:767px){
.h_header_wrap .h_header{height: 50px;}
.h_header_wrap .h_header .h_logo{
    text-indent: -6000px;
    margin:0 auto;
    width:45px;
    padding:0;
    line-height: 50px;
    background-position: center center;
}
.h_header_wrap .h_header .h_nav{
    display: none;
    overflow: auto;
    position: fixed;
    top:0; right:-85%;
    width:85%; height:100vh;
    background: #fff;
    z-index: 10;
}
.h_header_wrap .h_header .h_nav.on{
    display: block;
    right: 0;
}
.h_header_wrap .h_header .h_toggle{
    position: absolute;
    right: 0; top:0;
    width:24px; height: 50px;
    margin-right: 10px;
    text-indent: -6000px;
    background: url(../img/toggle.png) center center no-repeat;
    cursor: pointer;
}
.h_toggle_close{
    position: fixed;
    display: none;
    text-indent: -6000px;
    left:0; top:0; bottom:0;
    width:15%;
    height: 100vh;
    z-index: 10;
    background: rgba(0,0,0,0.3);
    opacity: 0;
}
.h_toggle_close.on{display: block; left:0;opacity: 1;}
.h_header_wrap .h_header .h_nav ul.h_level1{
    flex-wrap: wrap;
}
.h_header_wrap .h_header .h_nav li.h_level1{
    width:100%;
    margin-left: 0;
    line-height: 50px;
    font-size: 1.071em; /* 15px */
    box-sizing: border-box;
}
.h_header_wrap .h_header .h_nav li.h_level1 > p,
.h_header_wrap .h_header .h_nav li.h_level1 > a{
    height: 50px;
    line-height: 50px;
    padding:0 20px;
    border-bottom: 1px solid #efefef;
    color: #333;
    cursor: pointer;
}
.h_header_wrap .h_header .h_nav li.h_level1 > p::after{
    position: absolute;
    right:20px;
    font-weight: 100;
    font-size: 20px;
    content: '+';
    color: #ccc;
}
.h_header_wrap .h_header .h_nav li.h_level1 > p.on::after{content: '-';}
.h_header_wrap .h_header .h_nav li.h_level1 > a.m_skip{display: none;}
.h_header_wrap .h_header .h_nav ul.h_level2{
    position: inherit;
    display: none;
    width:100%;
    background: #efefef;
    padding:0;
}
.h_header_wrap .h_header .h_nav ul.h_level2.on{display: block;}
.h_header_wrap .h_header .h_nav li.h_level2 > p,
.h_header_wrap .h_header .h_nav li.h_level2 > a{
    padding-left: 30px;
    cursor: pointer;
}
.h_header_wrap .h_header .h_nav li.h_level2 > p::after{
    position: absolute;
    right:20px;
    font-weight: 100;
    font-size: 20px;
    content: '+';
    color: #ccc;
}
.h_header_wrap .h_header .h_nav li.h_level2 > p.on::after{ content: '-';}
.h_header_wrap .h_header .h_nav li.h_level2 > a.m_skip{display: none;}
.h_header_wrap .h_header .h_nav ul.h_level3{display: none;}
.h_header_wrap .h_header .h_nav ul.h_level3.on{display: block;}
.h_header_wrap .h_header .h_nav li.h_level3{
    background: #e6e6e6;
}
.h_header_wrap .h_header .h_nav li.h_level3 > a,
.h_header_wrap .h_header .h_nav li.h_level3 > p{
    padding-left: 40px;
}
}
/* 대시보드 버튼 */
.h_header_wrap .top{}
.h_header_wrap .top ul{
    display: flex;
    flex-wrap: nowrap;
    justify-content:flex-end;
    max-width:1280px;
    margin:0 auto;
    padding:0 20px;
    line-height: 30px;
    box-sizing: border-box;
}
.h_header_wrap .top ul li a{
    display: inline-block;
    margin-top:24px;
    padding:0 15px;
    line-height: 30px;
    font-size: 0.929em;
    color: #fff;
    border-radius: 20px;
    background: #1e90ff;
}
@media screen and (max-width:1279px) and (min-width:768px){
    .h_header_wrap .top ul li a{margin-top:20px;}
}
@media screen and (max-width:768px){
    .h_header_wrap .top{width:0;}
    .h_header_wrap .top ul li a{
        position: absolute;
        left:0; top:0;
        margin:10px;
        text-indent: -6000px;
        background: url(../img/key.png) center center no-repeat;
    }
}
