@import url(reset.css);
@import url(header.css);
@import url(footer.css);
@import url(contents.css);
@import url(subcon.css);

.modal_open{overflow: hidden;}
.textcenter{text-align: center;}
.textleft{text-align: left;}
@media screen and (min-width:1279px){
    .pc_skip{display: none;}
}
@media screen and (max-width:1279px) and (min-width:768px){
    .tpc_skip{display: none;}
    body{font-size: 12px;}
}
@media screen and (max-width:767px){
    .m_skip{display: none;}
    body{font-size: 10px;}
}