.history-container {
    width: 70%;
    margin:0 auto;
    padding:20px 0;
    box-sizing: border-box;
}
.history h2 {position: relative; font-size: 33px; color: #000; line-height: 1;}
.history h2:before {
content:""; position: absolute;
left:-48px; top:50%; transform: translateY(-50%);
width: 17px; height: 17px; border-radius: 100%;
background:#fff; border: 5px solid #1e90ff;
box-sizing: border-box;}

.history div {position: relative; padding: 0 0 0 48px;}
.history div:before { content:""; position:absolute; left:8px; top:7px;
      width: 2px; height:100%; background:#ddd;}
.history div:first-child:before { top:10px; height:calc(100% - 10px);}


.history ol { padding: 20px 0 74px; }
.history li {font-size: 17px; color:#000; line-height: 29px;  }
@media screen and (max-width: 768px){
    .history-container {
        width : 90%;
    }
}
