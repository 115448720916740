.contract-table:hover{
    background : #e3e3e3;
}

.complete-contract-list {
    height: 400px;
    overflow: auto;
}
.complete-contract-item {
    font-size: 15px;
    margin: 10px 0px;
    border-bottom: 1px solid #4D4D4D;
}
.complete-contract-item:hover {
    background: #b5b5b5;
}