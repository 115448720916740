/****** 메인배너 ******/
.h_main_banner{
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    width:100%; height: 600px;
}
    /**** 메인배너 텍스트 ****/
    .h_main_banner .textbox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width:40%; height: 100%;
        padding-right: 40px;
        text-align: right;
        color: #fff;
        box-sizing: border-box;
        background: #333;
    }
        .h_main_banner .textbox .textbox_inner{
            position: absolute;
            left:50%;
            margin-left:-640px;
        }
            .h_main_banner .textbox h2{
                font-size: 2.143em;
                text-align: left;
                line-height: 1.4;
                font-weight: 300;
                color: #fff;
            }
                .h_main_banner .textbox h2 span{color: #ffbc00;}
            .h_main_banner .textbox p{
                font-size: 1.286em;
                text-align: left;
                color: #aaa;
            }
            .h_main_banner .textbox p.fs_14{
                margin-top:10px;
                font-size: 1.143em;
            }
                .h_main_banner .textbox p.fs_14 a{
                    position: relative;
                    color: #aaa;
                    margin-right: 20px;
                }
                .h_main_banner .textbox p.fs_14 a:last-of-type{margin-right: 0;}
                .h_main_banner .textbox p.fs_14 a::before{
                    position: absolute;
                    display: block;
                    right:-12px; top:12px;
                    width:1px; height: 1px;
                    content: '';
                    background: #aaa;
                }
                .h_main_banner .textbox p.fs_14 a:last-of-type::after{display: none;}
                .h_main_banner .textbox p.fs_14 a.btn_ask{
                    display: inline-block;
                    text-align: left;
                    margin-top:20px;
                    padding:5px 20px;
                    font-size: 0.938em;
                    font-weight: 300;
                    color: #aaa;
                    border-radius: 5px;
                    border:1px solid #aaa;
                    transition-duration: 0.3s;
                }
                .h_main_banner .textbox p.fs_14 a.btn_ask:hover{
                    color: #fff;
                    border:1px solid #1e90ff;
                    background: #1e90ff;
                }

@media screen and (max-width:1279px){
.h_main_banner{
    position: relative;
    height: 80vh;
    max-height: 450px;
}
    .h_main_banner .textbox{
        position: absolute;
        width:100%;
        background: none;
        z-index: 4;
    }
    .h_main_banner .textbox .textbox_inner{
        left:0;
        margin-left:0;
        width:100%;
    }
    .h_main_banner .textbox h2,
    .h_main_banner .textbox p,
    .h_main_banner .textbox p.fs_14 a{
        text-align: center;
        color: #fff;
    }
    .h_main_banner .textbox p.fs_14 a.btn_ask{
        color: #fff;
        border-color:#fff;
    }
}
@media screen and (max-width:767px){
    .h_main_banner .textbox p,
    .h_main_banner .textbox p.fs_14{
        font-size: 1em;
        word-break: keep-all;
        padding:0 20px;
        margin:0;
    }
    
}
    /**** 메인배너 이미지 ****/
    .h_main_banner .imgbox{width:60%;}
    .h_main_banner .imgbox::before{
        position: absolute;
        right: 0;
        width:60%; height: 600px;
        text-align: center;
        background: #333;
        opacity: 0.2;
        content: '';
        z-index: 1;
    }
        .h_main_banner .imgbox img{
            width:100%; height: 100%;
            object-fit: cover;
        }
    @media screen and (max-width:1279px){
        .h_main_banner .imgbox{width:100%;}
        .h_main_banner .imgbox::before{width:100%; height: 80vh; opacity: 0.35;}
    }

/****** 메인컨텐츠 ******/
.main_contents{
    position: relative;
    margin:0 auto 0;
    background: #fff;
    z-index: 3;
}
    .main_contents .bg_light{
        overflow: hidden;
        background: url(../img/bg3.jpg) center center no-repeat;
    }
        /**** 사업 설명 ****/
        .main_contents .info_list{
            display: flex;
            flex-wrap: nowrap;
            max-width: 1280px;
            margin:80px auto;
        }
    @media screen and (max-width:1279px) and (min-width:768px){
        .main_contents .info_list{
            margin:40px auto;
            padding:0 20px;
        }
    }
            .main_contents .info_list .info_con{
                position: relative;
                margin-right: 10px;
                width:33.3%;
                border:1px solid #efefef;
                box-sizing: border-box;
            }
        @media screen and (max-width:767px){
            .main_contents .info_list{
                flex-wrap: wrap;
                margin:30px 0;
                padding:0 10px;
            }
            .main_contents .info_list .info_con{
                width:100%;
                margin:0 0 10px;
            }
            .main_contents .info_list .info_con:last-of-type{margin-bottom: 0;}
        }
            .main_contents .info_list .info_con:last-of-type{margin-right: 0;}
                .main_contents .info_list .info_con .imgbox{height: 180px;}
                    .main_contents .info_list .info_con .imgbox img{
                        width:100%; height: 100%;
                        object-fit: cover;
                    }
                .main_contents .info_list .info_con .textbox{
                    overflow: hidden;
                    padding:20px 25px 50px;
                }
                @media screen and (max-width:1279px) and (min-width:768px){
                    .main_contents .info_list .info_con .imgbox{height: 150px;}
                    .main_contents .info_list .info_con .textbox{padding:15px 20px 40px;}
                }
                    .main_contents .info_list .info_con .textbox .tit{
                        font-size: 1.286em; /* 18px */
                        margin-bottom: 10px;
                    }
                    .main_contents .info_list .info_con .textbox p{word-break: keep-all;}
                    .main_contents .info_list .info_con .textbox a{
                        position: absolute;
                        right:30px; bottom:18px;
                        display: inline-block;
                        margin-top:15px;
                        font-size: 0.929em;
                        color: #1e90ff;
                    }
                    .main_contents .info_list .info_con .textbox a:hover{text-decoration: underline;}
                @media screen and (max-width:767px){
                    .main_contents .info_list .info_con .textbox{padding:15px 15px 35px;}
                    .main_contents .info_list .info_con .textbox a{bottom: 13px;}
                }

        /**** 자료실 목록 ****/
        .main_contents .reference_list{
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            max-width: 1280px;
            margin:80px auto;
        }
    @media screen and (max-width:1279px) and (min-width:768px){
        .main_contents .reference_list{
            padding:0 20px;
            box-sizing: border-box;
        }
    }
            /** 탭 **/
            .main_contents .reference_list .tab{width:200px;}
                .main_contents .reference_list .tab li{
                    height: 50px; min-width:180px;
                    line-height: 50px;
                    padding:0 20px;
                    box-sizing: border-box;
                    border:1px solid #efefef;
                    border-bottom: none;
                    background: #fff;
                    cursor: pointer;
                }
                .main_contents .reference_list .tab li:last-of-type{border-bottom: 1px solid #efefef;}
                .main_contents .reference_list .tab li.on{
                    color: #fff;
                    background: #1e90ff;
                    border-color: #1e90ff;
                }

            @media screen and (max-width:767px){
                .main_contents .reference_list{
                    flex-wrap: wrap;
                    margin:60px 0;
                    padding:0 10px;
                    box-sizing: border-box;
                }
                .main_contents .reference_list .tab{
                    display: flex;
                    flex-wrap: nowrap;
                    width:100%;
                }
                .main_contents .reference_list .tab li{
                    width:33.3%; min-width: auto; height: 40px;
                    line-height: 40px;
                    text-align: center;
                    border-bottom: 1px solid #efefef;
                    border-right: none;
                    padding:0;
                }
                .main_contents .reference_list .tab li:last-of-type{
                    border-radius: 1px;
                }
            }

            /** 게시판 제목 목록 **/
            .main_contents .reference_list .reference_con{
                display: none;
                position: relative;
                width:640px; height: 265px;
                padding:30px;
                box-sizing: border-box;
                background: #fff;
            }

        @media screen and (max-width:1279px) and (min-width:768px){
            .main_contents .reference_list .reference_con{height: 250px;}
        }
            .main_contents .reference_list .reference_con.on{display: block;}
                .main_contents .reference_list .reference_con .tit{
                    font-size: 1.286em; /* 18px */
                    margin:0 0 20px;
                }
                .main_contents .reference_list .reference_con .more{
                    position: absolute;
                    right: 0; top:0;
                    height: 50px; width:50px;
                    text-indent: -6000px;
                    background: url(../img/icon_plus.png) center center no-repeat, #1e90ff;
                }
                .main_contents .reference_list .reference_con .board_list{}
                    .main_contents .reference_list .reference_con .board_list li{
                        position: relative;
                        margin-bottom: 10px;
                        padding-left: 10px;
                        width:100%;
                    }
                    .main_contents .reference_list .reference_con .board_list li:last-of-type{margin-bottom: 0;}
                    .main_contents .reference_list .reference_con .board_list li::before{
                        position: absolute;
                        top:11px; left: 0;
                        display: block;
                        width:2px; height: 2px;
                        background: #aaa;
                        content: '';
                    }
                        .main_contents .reference_list .reference_con .board_list li a{
                            display: block;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                        .main_contents .reference_list .reference_con .board_list li a:hover{
                            text-decoration: underline;
                        }
        @media screen and (max-width:767px){
            .main_contents .reference_list .reference_con{
                height: 200px; width:100%;
                padding:25px 20px 20px;
            }
            .main_contents .reference_list .reference_con .tit{margin-bottom: 15px;}
            .main_contents .reference_list .reference_con .more{
                width:40px; height: 40px;
                background-size: 35px;
            }
            .main_contents .reference_list .reference_con .board_list li{margin-bottom: 7px;}
            .main_contents .reference_list .reference_con .board_list li::before{
                top:8px;
                width:1px; height: 1px;
            }
        }
            /** 이미지 **/
            .main_contents .reference_list .imgbox{
                position: relative;
                width:400px;
            }
                .main_contents .reference_list .imgbox img{
                    position: absolute;
                    left:0; top:0;
                    width:100%; height: 100%;
                    object-fit: cover;
                    opacity: 0;
                    transition-duration: 0.3s;
                }
                .main_contents .reference_list .imgbox img.on{
                    left:0; top:0;
                    position: absolute;
                    opacity: 1;
                }

            @media screen and (max-width:900px){
                .main_contents .reference_list .imgbox{display: none;}
            }