.org-container {
    margin: 30px 0px;
}
.org h4{
    font-weight: 400;
    color : #1e90ff;
}
.org table {
    width: 100%;
    text-align: center;
}
.org table th {
    background: #dddddd;
    border: 1px solid #5f5f5f3a;
    font-size: 15px;
}
.org table td {
    border: 1px solid #dddddd;
    font-size: 13px;
}
.org table ul {
    margin : 10px 0px;
}
.org table img {
    width: 150px;
    height: 150px;
}

.org-category {
    border: 0;
    outline: 0;
    width: 100%;
    font-size: 20px;
    border : 1px solid #dddddd
}
.org-category:hover {
    background-color: #1e90ff;
    color : white;
}
.org-category-active {
    background-color: #1e90ff;
    color : white;
}
.org-category-container {
    width: 100%;
    display: flex;
    text-align: center;
    margin : 20px 0px 40px 0px;

}

.manpower-content {
    text-align: left;
    padding-left: 10px;
}